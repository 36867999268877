<template>
  <HeaderComponent /> 
  <MobileMenu />
  <BannerInitial />
  <GlobeComponent :coins="coins" />
  <AreaOptions />
  <CustomizerOptions :coins="coins" />
  <ElementIncluded />
  <StunningDemos />
  <CourseShowcase />
  <FaqSection />
  <FooterLanding />
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import HeaderComponent from '@/components/HeaderComponent.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import BannerInitial from '@/components/BannerInitial.vue'
import AreaOptions from '@/components/AreaOptions.vue'
import CustomizerOptions from '@/components/CustomizerOptions.vue'
import StunningDemos from '@/components/StunningDemos.vue'
import CourseShowcase from '@/components/CourseShowcase.vue'
import ElementIncluded from '@/components/ElementIncluded.vue'
import FaqSection from '@/components/FaqSection.vue'
import FooterLanding from '@/components/FooterLanding.vue'
import GlobeComponent from '@/components/GlobeComponent.vue'

export default {
  name: 'BodyComponent',
  components: {
    HeaderComponent,
    MobileMenu,
    BannerInitial,
    AreaOptions,
    CustomizerOptions,
    StunningDemos,
    CourseShowcase,
    FaqSection,
    FooterLanding,
    GlobeComponent,
    ElementIncluded,
  },
  setup() {
    const coins = ref([]);
    let socket;

    const initializeWebSocket = () => {
      socket = new WebSocket('wss://ws.coincap.io/prices?assets=ALL');

      socket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        
        coins.value = coins.value.map(coin => {
          if (data[coin.id]) {
            coin.priceIncreased = parseFloat(data[coin.id]) > parseFloat(coin.price);
            coin.priceDecreased = parseFloat(data[coin.id]) < parseFloat(coin.price);
            coin.price = data[coin.id];
            
            if (coin.priceIncreased || coin.priceDecreased) {
              setTimeout(() => {
                coin.priceIncreased = false;
                coin.priceDecreased = false;
              }, 1000);
            }
          }
          return coin;
        });
      };
    };

    const initializeCryptoData = async () => {
      try {
        const startRank = 381;
        const endRank = 410;
        const limit = endRank - startRank + 1;
        const offset = startRank - 1;

        const response = await axios.get(`https://api.coincap.io/v2/assets?limit=${limit}&offset=${offset}&order=rank`);
        
        coins.value = response.data.data.map(coin => ({
          id: coin.id,
          icon: `https://assets.coincap.io/assets/icons/${coin.symbol.toLowerCase()}@2x.png`,
          name: coin.name,
          symbol: coin.symbol,
          price: coin.priceUsd,
          marketCap: coin.marketCapUsd,
          vwap24Hr: coin.vwap24Hr,
          supply: coin.supply,
          volume: coin.volumeUsd24Hr,
          changePercent24Hr: coin.changePercent24Hr
        }));
      } catch (error) {
        console.error('Error fetching coin data:', error);
      }
    };

    onMounted(() => {
      initializeCryptoData();
      initializeWebSocket();
    });

    onUnmounted(() => {
      if (socket) {
        socket.close();
      }
    });

    return {
      coins
    };
  }
}
</script>